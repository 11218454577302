

import {
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRadio,
    IonToggle,
    IonImg,
    IonRow,
    IonRadioGroup,
    IonGrid,
    IonCol
} from '@ionic/vue';
import FixedFooter from "@/components/FixedFooter.vue";
import router from "@/router";
import {computed, defineComponent, ref} from "vue";
import {presentValidationAlert} from "@/services/Notify";
import {execute} from "@/mixins/LoadingMixin";
import {useRoute} from "vue-router";
import {getTemplate, updateTemplate, createTemplate} from "@/services/TemplateService";
import {JourneyTemplate} from "@/models/JourneyTemplateModel";
import {EMPTY_GUID} from "@/utils/GuidUtil";
import {createTabs, deleteTab} from "@/services/TabService";
import {JourneyTab} from "@/models/JourneyTabModel";
import {uuid4} from "@capacitor/core/dist/esm/util";
import {useStore} from "vuex";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";

export default defineComponent({
    name: 'TemplateName',
    components: {
        IonContent,
        IonPage,
        IonItem,
        IonLabel,
        IonInput,
        IonRadioGroup,
        IonRadio,
        IonToggle,
        IonImg,
        IonRow,
        IonGrid,
        IonCol,
        FixedFooter
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.language.templateName);
        this.template = {} as JourneyTemplate;
        if (this.templateId !== EMPTY_GUID) {
            router.currentRoute.value.meta.goBackTo = 'templates';
            await execute(async () => {
                this.template = await getTemplate(this.templateId);
                this.originalTabsCount = this.template.tabsCount;
            }, this.language.errors.gettingTemplate);
        } else {
            router.currentRoute.value.meta.goBackTo = 'home';
            this.template.tabsCount = 3;
            this.template.name = '';
        }
        if (!this.template.enableCustomContent) {
            this.template.enableCustomContent = false;
        }
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const route = useRoute();
        const templateId = computed(() => route.params.id as string || EMPTY_GUID);
        const template = ref({} as JourneyTemplate);
        const originalTabsCount = ref(0);
        const editingExisting = computed(() => templateId.value !== EMPTY_GUID);
        const currentTabCount = ref(0);
        
        const handleTabsCountChanged = async () => {
            if (template.value.tabsCount === 3) {
                await deleteTab(template.value.tabs[2].id);
            }
            else {
                await createTabs([{
                    id: uuid4(),
                    title: '',
                    tabNum: 3,
                    templateId: templateId.value
                }] as JourneyTab[]);
            }
        }
        
        const handleNext = async () => {
            if (!template.value.name.trim()) {
                presentValidationAlert(language.value.validation.enterName);
            } else {
                await execute(async () => {
                    const tabCountChanged = originalTabsCount.value !== 0 && originalTabsCount.value !== template.value.tabsCount;
                    if (tabCountChanged) {
                        await handleTabsCountChanged();
                    }
                    if (editingExisting.value) {
                        await updateTemplate({ id: template.value.id, name: template.value.name , tabsCount: template.value.tabsCount, complete: template.value.complete, appInstanceId: template.value.appInstanceId, enableCustomContent: template.value.enableCustomContent });
                    } else {
                        template.value.id = uuid4();
                        await createTemplate({ id: template.value.id, name: template.value.name , tabsCount: template.value.tabsCount, complete: false, appInstanceId: store.getters['app/selectedAppInstanceId'], enableCustomContent: template.value.enableCustomContent });
                        await router.replace({name: 'templateName', params: {id: template.value.id }});
                    }
                    await router.push({name: 'tabTitles', params: {id: template.value.id }});
                }, language.value.errors.savingTemplate);
            }
        }

        return {
            store,
            language,
            template,
            templateId,
            footerButtons: computed(() =>
                new FooterButtonsBuilder()
                    .addClearBackButton()
                    .addPrimaryNextButton(async () => await handleNext())
                    .create()
            ),
            radioSelected: (e: any) => template.value.tabsCount = parseInt(e.target.value, 10),
            originalTabsCount,
            currentTabCount
        }
    }
});
